.PieGraphGroupPrint{
    text-align: center;
    justify-content: center;
    border: 1px solid rgb(64, 63, 63);

    .NoData{
        margin: 180px;
        color: #707070;
        text-align: center;
    }
}