
/* spacing */
.mx-3{
    margin: 0 1rem;
  }
  .mr-1{
    margin-right: 0.5rem;
  }
  .ml-1{
    margin-left: 0.5rem;
  }
  .mb-1{
    margin-bottom: 0.5rem;
  }
  .mb-3{
    margin-bottom: 1rem;
  }
  .p-1{
    padding: 0.5rem;
  }
  .p-3{
    padding: 1rem;
  }
  .py-1{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; 
  }
  .px-5{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .pl-1{
    padding-left: 0.5rem;
  }
  .pl-2{
    padding-left: 0.75rem;
  }
  .pl-3{
    padding-left: 1rem;
  }
  .pt-1{
    padding-top: 0.5rem;
  }
.mx-1{
  margin: 0 auto;
}
.mx-5{
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.pr-1{
  padding-right: 0.5rem;
}

.pr-2{
  padding-right: 0.75rem;
}

.pr-3{
  padding-right: 1rem;
}

.my-1{
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.my-2{
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
}
.my-3{
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.ml-3{
  margin-left: 1rem;
}
.mr-3{
  margin-right: 1rem;
}