.SideLabel{
    padding: 1.5rem;
    display:table;
    span{
    display:table-cell;
    vertical-align:middle;
    text-align: center;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    white-space: nowrap;
    color: #D1D1D1;
    }
}
.Graficos{
    justify-content: center;
}
.cinza-claro{
    background-color: #848484;    
}
.cinza-escuro{
    background-color: #636363;
}