.efficiency-table{

    table{
        border-collapse: collapse;
        text-indent: initial;
        border-spacing: 1px;
        margin: 5px 10px;
        .theader, .theader-texto-cinza{
            background-color: #182230;
            font-weight: bold;
        }
        td{
            text-align: center;
        }
        th{
            text-align: center; 
        }
        tr{
            margin:10px;
        }
    }
    .efficiency-column{
        width: 30%;
        padding-right: 7px;
    }
    .progress-bar{
        height: 30px;
        border-radius: 5px;
        margin: 5px 5px;
        border: None;
        overflow: hidden;
        position: relative;
        background-color: cyan;
        
    }
}
