.dailyreport{
    .MuiOutlinedInput-notchedOutline {
        border-color: #FFF !important;
    }
    ::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }

    .row-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }
    
    .row-title-column {
        min-width: 30%;
        text-align: left
    }
    .pocoinfos{
        margin-bottom: 10px;
        height: 10
    }
    .pocoinfos-row{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }
    .pocoinfos-column{
        min-width: 30%;
        text-align: center
    }
    .border{
        border-color: grey;
        border-style: solid;
        border-left-width: 1px;
        border-right-width: 1px;
        border-bottom-width: 0;
        border-top-width: 0;
    }
    div > .poco-info {
        padding: 0.2rem 0 !important;
    }
    .Logo{
        visibility:hidden
    }
    .poco-info > span{
        margin: auto;
        flex: 1 1;
    }
    .Reports{
        width: 100%;
    }
    
}
@media print{
    @page {size: landscape}
    html{
        background: white;
    }
    .dailyreport{
        .flex-graph-print{
            flex: 0.8;
        }
        .pagetitle span {
            font-size: 1.5em;
        }
        .Page1{
            page-break-after: always !important;
        }
        .Operacoes{
            page-break-after: always !important;
        }
        .Reports{
            break-inside: avoid;
            width: 100%;
        }
        .Lessonlearned{
            break-inside: avoid;
            break-after: always;
        }
        .Efficiency{
            page-break-before: always !important;
            break-inside: avoid !important;
        }
        .Npt{
            break-inside: avoid;
        }
        .Navbar{
            display: none
        }
        .Breadcrumble{
            display: none
        }
        .Select{
            display: none
        }
        .BreakForPrint { 
            break-inside: avoid;
            break-before: auto;
          } 
        .GraficosPrint{
            break-inside: avoid;
            // break-after: always;
            justify-content: left;
        }
        .BreakGraficos{
            display: none;
        }
        .Graficos{
            display: none;
        }
        .Logo{
            visibility: visible !important;
            filter: invert(80%) !important;
        }
        .div-agua{
            filter:invert(80%);
        }
        .div-graficos{
            div{
                border: 1.5px solid rgb(30, 29, 29) !important;}
            .barraBroca{
                filter:invert(70%) }
            }
        .borda{
            filter:invert(80%)
        }
        .theader-texto-cinza {
            background-color: #BABABA !important;
        }
        th{
            color: black
        }
        .text-success{
            color: rgb(6, 100, 6) !important
        }
        .rotate{
            background-color: #D0D0D0 !important;
        }
        .text-cinza-escuro{
            color: #434343 !important
        }
        .Efficiency, .Npt, .Lessonlearned{
            table, th, td {
                border: 1px solid black;
            }
        }
        .efficiency-table table th{
            .barraEfficiency{
                padding-right: 70% !important;
            }
        }
        .pocoinfos-row span, label{
            font-size: 0.6em !important;
        }
        div > .poco-info {
            padding: 0.1rem 0 !important;
        }
        .infos-tabelas .text-destacado td {
            color: black
        }
        .infos-tabelas .destaque td {
            border-bottom: 1px solid black;
        }
        .plataforma{
            max-height: 550px;
        }
        .text-white{
            color: black !important;
        }
        .div-legendas .text-white{
            color: black !important;
            filter: invert(1)
        }
        
    }
  }
@media only screen { 
    .BreakForPrint { 
      display: none; 
    } 
  }