@import './styles/variables.scss';
@import './styles/typography.scss';

// TODO corrigir espaço que sobra nas verticais ao colocar font como Cairo
@include font('Cairo-Medium', '../assets/fonts/Cairo/static/Cairo-Medium');
@include font('Cairo-Regular', '../assets/fonts/Cairo/static/Cairo-Regular');
@include font('Cairo-Bold', '../assets/fonts/Cairo/static/Cairo-Bold');
@include font('Cairo-SemiBold', '../assets/fonts/Cairo/static/Cairo-SemiBold');
@include font('Cairo-ExtraBold', '../assets/fonts/Cairo/static/Cairo-ExtraBold');

@import './styles/flex.scss';
@import './styles/spacing.scss';


body {
  margin: 0;
  font-family: 'Arial', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // font-family: 'Araial', 'Cairo-SemiBold', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html:has(#login) {
  background: url("./assets/images/login-bg.png") no-repeat center center fixed;
  background-size: cover;
  height: 100%;
  padding: 0
}

@media print {
  html {
    background: $background-blue;
    // background: $background-linear;
  }
}

html {
  padding: 0.75rem;
  box-sizing: border-box;
  background: $background-blue;
  background: $background-linear;
  background-attachment: fixed;
}

html,
body,
#root {
  min-height: 100%;
  margin: 0;
}

.App {
  text-align: center;
  height: 100%;
}

.pointer {
  cursor: pointer;
}

.h-100 {
  height: 100%;
}

.vh-100 {
  height: 100vh;
}

.vh-80 {
  height: 80vh;
}

.vh-50 {
  height: 50vh;
}

.vh-10 {
  height: 10vh;
}

.w-100 {
  width: 100%;
}

/* colors */
.bg-cinza {
  background-color: $main-bg-color;
}

.bg-branco {
  background-color: $white-alpha-800;
}

.text-cinza-escuro {
  color: #989898 !important;
}

.text-white {
  color: #E5E7E9 !important;
}

.text-danger {
  color: #AE133C !important;
}

.text-success {
  color: #73CF57 !important;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

/* font */
.font-small {
  font-size: 0.8em;
}

.font-xsmall {
  font-size: 0.6em;
}

.font-bold {
  //font-family: 'Cairo-ExtraBold', sans-serif;
  font-weight: bolder;
}

.uppercase {
  text-transform: uppercase;
}


/* grid */
.row {
  display: flex;
  flex-direction: row;
}


.rotate {
  vertical-align: middle;
  text-align: center;
  background-color: #3D4D5A;
  padding: .5rem;
  position: relative;

  span {
    -ms-writing-mode: tb-rl;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    white-space: nowrap;
    position: absolute;
    top: 35%;
    left: 10%;
  }
}

.p-relative {
  position: relative;
}