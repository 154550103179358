@import "../../styles/variables.scss";

.navbar{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    
}

.navbar a{
    color: white;
    font-size: 1.2rem;
    text-decoration: none;

}

.navbar ul{
    list-style: none;
    display: flex;
    gap: 2rem;

}