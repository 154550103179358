/* Define a container for the iframes */
.iframe-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Define 3 equal columns */
    gap: 10px; /* Optional: adds space between the iframes */
    padding: 10px; /* Optional: adds padding around the container */
  }
  
  /* Style the iframes */
  .iframe-container iframe {
    width: 100%;
    height: 450px; /* You can adjust the height as needed */
    border: none; /* Removes the default iframe border */
  }