.metas{
    padding: 1rem;
    .progresso{
        height: 10px;
        width: 100%;
        border-radius: 10px;
        margin-bottom: .25rem;
    }
    .MuiChip-root{
        height: auto;
        font-size: .7rem;
    }
    .MuiPaper-root{
        color: #fff !important;
        flex: 1;
        padding: .1rem 1rem;
        &.MuiAlert-outlinedInfo{
            background-color: #3D475ADD !important;
            border: 1px solid #fff;
        }
        .MuiAlert-message{
            flex: 1;
            // font-weight: bold;
        }
    }
}