@import "../../styles/variables.scss";

.infos-poco{
  letter-spacing: -1px;
  position: relative;
  margin-bottom: 25px;
  .border{
    border: 1px solid #000;
  }
  // remove linha da label do grafico
  .recharts-pie-label-line{
    display: none
  }
  .graficos{
    flex: 1;
    align-items: flex-end;
    border-color: grey;
    border-style: solid;
    border-left-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 0;
    border-top-width: 0;
  }

  div>.poco-info{
    padding: 1rem 0;
    font-size: 1rem;
  }

  .info-operacao .poco-info{
    label{
      flex: 1
    }
  }
  .info-base>div, .info-operacao>div{
    padding: 2px 0;
  }
  .pessoa{
    min-width: 8rem;
    text-align: left;
    flex-basis: 25%;
    .foto{
      width: 40px;
      height: 40px;
      background-position: center;
      background-size: cover;
      border-radius: 50px;
    }
    .dados-pessoa span{
      max-width: 5rem;
      white-space: nowrap; 
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  /* border cortadas */
  &::before{
    content: '';
    display: block;

    position: absolute;
    left: 0;
    bottom: -15px;

    width: 0; 
    height: 0; 
    border-bottom: 15px solid transparent;    
    border-right: 15px solid $main-bg-color;
  }
  &::after{
    content: '';
    display: block;

    position: absolute;
    left: 15px;
    width: calc(100% - 15px);
    bottom: -15px;
    height: 15px; 
    background-color:  $main-bg-color;
  }
}

.consistency{
  flex: 0.45;
}
