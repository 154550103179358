.reports {
    .Filter {
        text-align: center;
        height: 100%;
    }

    .Graficos {
        width: 100%;
        margin: 0;
    }

    .Grafico {
        width: 100%;
        height: 100%;
    }

    .Titulo {
        padding: 1.5rem;
        color: #D1D1D1;
    }

    .MuiOutlinedInput-notchedOutline {
        border-color: #FFF !important;
    }

    ::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }
}

.grid {
    display: grid;
    grid-template-columns: 45% 45%;
    background-color: hsla(0, 0%, 100%, .1);
    justify-content: center;
    column-gap: 15px;

    .title {
        color: white;
        margin-top: 2.5rem;
        text-align: center;
    }

    iframe.metabase-report {
        height: 25rem;
        width: 100%;
    }
}