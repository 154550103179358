.form-dialog-user{
    .form-container{
        background-color: rgba(255, 255, 255, 0.8)
    }
    .box-img{
        background-color: #000;
        border-radius: 15;
        padding: .5rem; 
        margin-top: 15;
    }
}