@import "../../styles/variables.scss";

.current-operation{
  .op-title:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      border-width: 4px;
      border-style: solid;
      border-color: $main-bg-color;
      bottom: 7px;
  }
  .op-title:before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    border-width: 0em 7px 7px 0;
    border-style: solid;
    border-color: transparent $main-bg-color transparent;
  }

  .op-title {
    width: 7px;
    position: relative;
  }

}