$background-blue: rgb(18,46,58);
$background-linear: linear-gradient(147deg, rgba(18,46,58,1) 0%, rgba(5,9,21,1) 100%);

$color-white: #fff;
$color-black: #000;
$main-bg-color: rgba(255,255,255,.10);
/* white alpha */
$white-alpha-50: rgba(255,255,255,0.04);
$white-alpha-100: rgba(255,255,255,0.06);
$white-alpha-200: rgba(255,255,255,0.08);
$white-alpha-300: rgba(255,255,255,0.16);
$white-alpha-400: rgba(255,255,255,0.24);
$white-alpha-500: rgba(255,255,255,0.36);
$white-alpha-600: rgba(255,255,255,0.48);
$white-alpha-700: rgba(255,255,255,0.64);
$white-alpha-800: rgba(255,255,255,0.80);
$white-alpha-900: rgba(255,255,255,0.92);

/* black alpha */
$black-alpha-50: rgba(0,0,0,0.04);
$black-alpha-100: rgba(0,0,0,0.06);
$black-alpha-200: rgba(0,0,0,0.08);
$black-alpha-300: rgba(0,0,0,0.16);
$black-alpha-400: rgba(0,0,0,0.24);
$black-alpha-500: rgba(0,0,0,0.36);
$black-alpha-600: rgba(0,0,0,0.48);
$black-alpha-700: rgba(0,0,0,0.64);
$black-alpha-800: rgba(0,0,0,0.80);
$black-alpha-900: rgba(0,0,0,0.92);

/* gray */
$gray-50: #F7FAFC;
$gray-100: #EDF2F7;
$gray-200: #E2E8F0;
$gray-300: #CBD5E0;
$gray-400: #A0AEC0;
$gray-500: #718096;
$gray-600: #4A5568;
$gray-700: #2D3748;
$gray-800: #1A202C;
$gray-900: #171923;

/* red */
$red-50: #FFF5F5;
$red-100: #FED7D7;
$red-200: #FEB2B2;
$red-300: #FC8181;
$red-400: #F56565;
$red-500: #E53E3E;
$red-600: #C53030;
$red-700: #9B2C2C;
$red-800: #B22727;
$red-900: #63171B;

/* orange */
$orange-50: #FFFAF0;
$orange-100: #FEEBC8;
$orange-200: #FBD38D;
$orange-300: #F6AD55;
$orange-400: #ED8936;
$orange-500: #DD6B20;
$orange-600: #C05621;
$orange-700: #9C4221;
$orange-800: #7B341E;
$orange-900: #652B19;

/* yellow */
$yellow-50: #FFFFF0;
$yellow-100: #FEFCBF;
$yellow-200: #FAF089;
$yellow-300: #F6E05E;
$yellow-400: #ECC94B;
$yellow-500: #D69E2E;
$yellow-600: #B7791F;
$yellow-700: #975A16;
$yellow-800: #744210;
$yellow-900: #5F370E;

/* green */
$green-50: #F0FFF4;
$green-100: #C6F6D5;
$green-200: #9AE6B4;
$green-300: #68D391;
$green-400: #48BB78;
$green-500: #38A169;
$green-600: #2F855A;
$green-700: #276749;
$green-800: #22543D;
$green-900: #1C4532;

/* teal */
$teal-50: #E6FFFA;
$teal-100: #B2F5EA;
$teal-200: #81E6D9;
$teal-300: #4FD1C5;
$teal-400: #38B2AC;
$teal-500: #319795;
$teal-600: #2C7A7B;
$teal-700: #285E61;
$teal-800: #234E52;
$teal-900: #1D4044;

/* blue */
$blue-50: #EBF8FF;

