/* flex */
.d-flex{
    display: flex;
    print-color-adjust: exact !important;
}
.d-grid{
  display: grid;
}
.flex-10{
  flex: 0.10;
}
.flex-20{
  flex: 0.20;
}
.flex-25{
  flex: 0.25;
}
.flex-30{
  flex: 0.30;
}
.flex-40{
  flex: 0.40;
}
.flex-50{
  flex: 0.50;
}
.flex-60{
  flex: 0.60;
}
.flex-78{
  flex: 0.78;
}
.flex-80{
  flex: 0.80;
}
.flex-85{
  flex: .85;
}
.flex-1{
  flex: 1;
}
.flex-2{
  flex: 2;
}
.flex-column{
  flex-direction: column;
}
.flex-wrap{
  flex-wrap: wrap;
}
.flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-justify-between{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-justify-around{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.flex-row-reverse{
  flex-direction: row-reverse;
}
.align-stretch{
  align-items: stretch;
}